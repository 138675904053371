interface DropcapTuningConfig {
  sizeMultiplier: number;
  verticalShiftMultiplier: number;
}

type FontVarient = "italic" | "bold" | "regular" | "bold-italic"

// TODO: Remove the helper tuning functions
// let customMultipliers: DropcapTuningConfig = {
//   sizeMultiplier: 1,
//   verticalShiftMultiplier: 1,
// };
// function getCustomTuningConfig(): DropcapTuningConfig {
//   const item = localStorage.getItem("customMultipliers");
//   if (item) {
//     const multipliers = JSON.parse(item);
//     return multipliers;
//   }
//   return {
//     sizeMultiplier: 1,
//     verticalShiftMultiplier: 1,
//   };
// }

// function getCustomTuningConfigInternal(): DropcapTuningConfig {
//   return customMultipliers;
// }

// function setCustomTuningConfig(
//   sizeMultiplier: number,
//   verticalShiftMultiplier: number
// ): void {
//   localStorage.setItem(
//     "customMultipliers",
//     JSON.stringify({
//       sizeMultiplier,
//       verticalShiftMultiplier,
//     })
//   );
// }

// function setCustomTuningConfigInternal(
//   sizeMultiplier: number,
//   verticalShiftMultiplier: number
// ): void {
//   customMultipliers = {
//     sizeMultiplier,
//     verticalShiftMultiplier,
//   };
// }

// Notes:
//  When tuning the dropcap configs, the verticalShiftMultiplier must be tuned first
//  and then you must move to the size multiplier.

const dropcapTuningConfigs: {
  [fontFamily: string]: { [varient in FontVarient]?: DropcapTuningConfig };
} = {
  ["Benne"]: {
    regular: {
      // tuned at 10pt x 1.5
      sizeMultiplier: 1.345,
      verticalShiftMultiplier: 0,
    },
    bold: {
      // tuned at 10pt x 1.5
      sizeMultiplier: 1.345,
      verticalShiftMultiplier: 0,
    },
    italic: {
      // tuned at 10pt x 1.5
      sizeMultiplier: 1.345,
      verticalShiftMultiplier: 0,
    }
  },
  ["Cardo"]: {
    regular: {
      // tuned at 10pt x 1.5
      sizeMultiplier: 1.02,
      verticalShiftMultiplier: -0.52
    },
    bold: {
      // tuned at 10pt x 1.5
      sizeMultiplier: 1.01,
      verticalShiftMultiplier: -0.71,
    },
    italic: {
      // tuned at 10pt x 1.5
      sizeMultiplier: 1.02,
      verticalShiftMultiplier: -0.52
    },
    "bold-italic": {
      // tuned at 10pt x 1.5
      sizeMultiplier: 1.02,
      verticalShiftMultiplier: -0.52
    }
  },
  ["CrimsonPro"]: {
    regular: {
      // tuned at 10pt x 1.5
      sizeMultiplier: 1.08,
      verticalShiftMultiplier: -0.8,
    },
    bold: {
      // tuned at 10pt x 1.5
      sizeMultiplier: 1.08,
      verticalShiftMultiplier: -0.8,
    },
    italic: {
      // tuned at 10pt x 1.5
      sizeMultiplier: 1.08,
      verticalShiftMultiplier: -0.8,
    },
    "bold-italic": {
      // tuned at 10pt x 1.5
      sizeMultiplier: 1.08,
      verticalShiftMultiplier: -0.8,
    }
  },
  ["EBGaramond"]: {
    regular: {
      // tuned at 10pt x 1.5
      sizeMultiplier: 0.994,
      verticalShiftMultiplier: -0.73,
    },
    bold: {
      // tuned at 10pt x 1.5
      sizeMultiplier: 0.99,
      verticalShiftMultiplier: -0.84,
    },
    italic: {
      // tuned at 10pt x 1.5
      sizeMultiplier: 0.99,
      verticalShiftMultiplier: -0.84,
    },
    "bold-italic": {
      // tuned at 10pt x 1.5
      sizeMultiplier: 0.99,
      verticalShiftMultiplier: -0.86,
    }
  },
  ["LibreBaskerville"]: {
    regular: {
      // tuned at 10pt x 1.5
      sizeMultiplier: 1.02,
      verticalShiftMultiplier: -0.4,
    },
    bold: {
      // tuned at 10pt x 1.5
      sizeMultiplier: 1.02,
      verticalShiftMultiplier: -0.4,
    },
    italic: {
      // tuned at 10pt x 1.5
      sizeMultiplier: 1.03,
      verticalShiftMultiplier: -0.42,
    },
    "bold-italic": {
      // tuned at 10pt x 1.5
      sizeMultiplier: 1.03,
      verticalShiftMultiplier: -0.42,
    }
  },
  ["LibreCaslon"]: {
    regular: {
      // tuned at 10pt x 1.5
      sizeMultiplier: 1.02,
      verticalShiftMultiplier: -0.4,
    },
    bold: {
      // tuned at 10pt x 1.5
      sizeMultiplier: 1.02,
      verticalShiftMultiplier: -0.4,
    },
    italic: {
      // tuned at 10pt x 1.5
      sizeMultiplier: 1.03,
      verticalShiftMultiplier: -0.42,
    },
    "bold-italic": {
      // tuned at 10pt x 1.5
      sizeMultiplier: 1.03,
      verticalShiftMultiplier: -0.42,
    }
  },
  ["Lora"]: {
    regular: {
      // tuned at 10pt x 1.5
      sizeMultiplier: 0.99,
      verticalShiftMultiplier: -0.71,
    },
    bold: {
      // tuned at 10pt x 1.5
      sizeMultiplier: 0.99,
      verticalShiftMultiplier: -0.71,
    },
    italic: {
      // tuned at 10pt x 1.5
      sizeMultiplier: 0.99,
      verticalShiftMultiplier: -0.71,
    },
    "bold-italic": {
      // tuned at 10pt x 1.5
      sizeMultiplier: 0.99,
      verticalShiftMultiplier: -0.71,
    }
  },
  ["OpenSans"]: {
    regular: {
      // tuned at 10pt x 1.5
      sizeMultiplier: 0.96,
      verticalShiftMultiplier: -0.72,
    },
    bold: {
      // tuned at 10pt x 1.5
      sizeMultiplier: 0.96,
      verticalShiftMultiplier: -0.72,
    },
    italic: {
      // tuned at 10pt x 1.5
      sizeMultiplier: 0.96,
      verticalShiftMultiplier: -0.72,
    },
    "bold-italic": {
      // tuned at 10pt x 1.5
      sizeMultiplier: 0.96,
      verticalShiftMultiplier: -0.72,
    }
  },
  ["OpenSansLT"]: {
    regular: {
      // tuned at 10pt x 1.5
      sizeMultiplier: 0.96,
      verticalShiftMultiplier: -0.72,
    },
    bold: {
      // tuned at 10pt x 1.5
      sizeMultiplier: 0.96,
      verticalShiftMultiplier: -0.72,
    },
    italic: {
      // tuned at 10pt x 1.5
      sizeMultiplier: 0.96,
      verticalShiftMultiplier: -0.72,
    },
    "bold-italic": {
      // tuned at 10pt x 1.5
      sizeMultiplier: 0.96,
      verticalShiftMultiplier: -0.72,
    }
  },
  ["OpenDyslexic"]: {
    regular: {
      // tuned at 10pt x 1.5
      sizeMultiplier: 1.06,
      verticalShiftMultiplier: -0.46,
    },
    bold: {
      // tuned at 10pt x 1.5
      sizeMultiplier: 1.18,
      verticalShiftMultiplier: -0.05,
    },
    italic: {
      // tuned at 10pt x 1.5
      sizeMultiplier: 1.19,
      verticalShiftMultiplier: -0.19,
    },
    "bold-italic": {
      // tuned at 10pt x 1.5
      sizeMultiplier: 1.19,
      verticalShiftMultiplier: -0.19,
    }
  },
  ["PTSans"]: {
    regular: {
      // tuned at 10pt x 1.5
      sizeMultiplier: 0.97,
      verticalShiftMultiplier: -0.83,
    },
    bold: {
      // tuned at 10pt x 1.5
      sizeMultiplier: 0.99,
      verticalShiftMultiplier: -0.83,
    },
    italic: {
      // tuned at 10pt x 1.5
      sizeMultiplier: 0.99,
      verticalShiftMultiplier: -0.83,
    },
    "bold-italic": {
      // tuned at 10pt x 1.5
      sizeMultiplier: 0.99,
      verticalShiftMultiplier: -0.83,
    }
  },
  ["Rosario"]: {
    regular: {
      // tuned at 10pt x 1.5
      sizeMultiplier: 1,
      verticalShiftMultiplier: -0.44,
    },
    bold: {
      // tuned at 10pt x 1.5
      sizeMultiplier: 1,
      verticalShiftMultiplier: -0.44,
    },
    italic: {
      // tuned at 10pt x 1.5
      sizeMultiplier: 1.02,
      verticalShiftMultiplier: -0.59,
    },
    "bold-italic": {
      // tuned at 10pt x 1.5
      sizeMultiplier: 1.02,
      verticalShiftMultiplier: -0.59,
    }
  },
  ["SourceSansPro"]: {
    regular: {
      // tuned at 10pt x 1.5
      sizeMultiplier: 1.01,
      verticalShiftMultiplier: -0.72,
    },
    bold: {
      // tuned at 10pt x 1.5
      sizeMultiplier: 1.02,
      verticalShiftMultiplier: -0.88,
    },
    italic: {
      // tuned at 10pt x 1.5
      sizeMultiplier: 1.01,
      verticalShiftMultiplier: -0.83,
    }
  },
  ["Spectral"]: {
    regular: {
      // tuned at 10pt x 1.5
      sizeMultiplier: 0.96,
      verticalShiftMultiplier: -0.86,
    },
    bold: {
      // tuned at 10pt x 1.5
      sizeMultiplier: 0.96,
      verticalShiftMultiplier: -0.86,
    },
    italic: {
      // tuned at 10pt x 1.5
      sizeMultiplier: 0.96,
      verticalShiftMultiplier: -0.92,
    },
    "bold-italic": {
      // tuned at 10pt x 1.5
      sizeMultiplier: 0.96,
      verticalShiftMultiplier: -0.92,
    }
  },
  ["TheanoDidot"]: {
    regular: {
      // tuned at 10pt x 1.5
      sizeMultiplier: 1.03,
      verticalShiftMultiplier: -0.55,
    },
    bold: {
      // tuned at 10pt x 1.5
      sizeMultiplier: 1.03,
      verticalShiftMultiplier: -0.55,
    },
    italic: {
      // tuned at 10pt x 1.5
      sizeMultiplier: 1.03,
      verticalShiftMultiplier: -0.55,
    },
    "bold-italic": {
      // tuned at 10pt x 1.5
      sizeMultiplier: 1.03,
      verticalShiftMultiplier: -0.55,
    }
  },
  ["YoungSerif"]: {
    regular: {
      // tuned at 10pt x 1.5
      sizeMultiplier: 1,
      verticalShiftMultiplier: -0.52,
    },
    bold: {
      // tuned at 10pt x 1.5
      sizeMultiplier: 1,
      verticalShiftMultiplier: -0.52,
    },
    italic: {
      // tuned at 10pt x 1.5
      sizeMultiplier: 1,
      verticalShiftMultiplier: -0.55,
    },
    "bold-italic": {
      // tuned at 10pt x 1.5
      sizeMultiplier: 1,
      verticalShiftMultiplier: -0.55,
    }
  }
};

const getDropcapTuningConfig = (fontFamily: string, fontVarient: FontVarient): DropcapTuningConfig => {
  const dropcapTuningConfig = dropcapTuningConfigs[fontFamily]?.[fontVarient];
  if (dropcapTuningConfig) {
    return dropcapTuningConfig;
  }

  const defaultConfig: DropcapTuningConfig = {
    // tuned for 10pt x 1.5 of Benne
    sizeMultiplier: 1.3,
    verticalShiftMultiplier: 0.24,
  };

  console.warn(
    `Dropcap tuning configs not available for ${fontFamily}. Returning default configs.`
  );
  return defaultConfig;
};

export {
  getDropcapTuningConfig,
};

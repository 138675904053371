import React, { FunctionComponent } from "react";
import AtticusAnimation_v2 from "../content/images/AtticusAnimation_v2.gif";


export const Maintenance: FunctionComponent = () => {
	return (
		<div className="maintenance-screen">
			<div className="container">
				<div className="loading-animation-container">
					<img className="loading-animation" src={AtticusAnimation_v2} />
				</div>
				<div className="maintenance-card">
					<h1>Under Maintenance</h1>
					<p>We&apos;re taking a breather to beat writer&apos;s block - we&apos;ll be back with fresh inspiration soon!</p>
				</div>
			</div>
		</div>
	);
};
import React from "react";
import {
  Radio,
  Checkbox,
  Form,
  Row,
  Col,
} from "antd";
import { observer } from "mobx-react";

const ParagraphSetting = () => {
  return (
    <div className="theme-section-tab-content">
      <Row gutter={16}>
        <Col>
          <Form.Item 
            className="at-form-item" 
            name="decorations" 
            label="First sentence"
          >
            <Checkbox.Group>
              <Checkbox value="dropcap">Drop caps</Checkbox>
              <Checkbox value="smallcaps">
                Lead in small caps
              </Checkbox>
            </Checkbox.Group>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col>
          <Form.Item
            className="at-form-item"
            name="beginFirstSentence"
            label="When to use the first sentence formatting"
          >
            <Radio.Group buttonStyle="solid">
              <Radio value="chapter">Beginning of chapter</Radio>
              <Radio value="break">
                Every paragraph following a break
              </Radio>
            </Radio.Group>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col>
          <Form.Item 
            className="at-form-item"
            name="firstline" 
            label="Subsequent paragraphs"
          >
            <Radio.Group>
              <Radio value="indented">Indented</Radio>
              <Radio value="spaced">Spaced</Radio>
            </Radio.Group>
          </Form.Item>
        </Col>
      </Row>
    </div>
  );
};

export default observer(ParagraphSetting);

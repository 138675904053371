import { DROP_CAP_START_SYMBOLS, getHtmlTagsForMarks } from "../helpers";

export const parseFirstSentence = (
  textNode: ISlateStore.SlateTextNode,
): string => {
  const wrappers: string[] = [];
  const { tagQueue, tagStack } = getHtmlTagsForMarks(textNode);
  const parsedText = textNode.text ? textNode.text : "";

  if (parsedText) {
    let dropcap = parsedText[0].toLocaleUpperCase();
    let sentence = parsedText;

    if (dropcap) {
      sentence = parsedText.substring(1);
      let charIndex = 0;

      while (
        DROP_CAP_START_SYMBOLS.includes(dropcap[charIndex]) &&
        parsedText[charIndex + 1]
      ) {
        dropcap += parsedText[charIndex + 1];
        sentence = sentence.substring(1);
        charIndex = charIndex + 1;
      }

      wrappers.push(
        `<span aria-hidden="true" class="dropcap">${dropcap}</span>`
      );
    }

    const words = sentence.split(" ");
    const parsedWords = words
      .map((word, i) =>
        i < 4 ? `<span aria-hidden="true" class="lead_word">${word} </span>` : `${word} `
      )
      .join("");

    wrappers.push(`${parsedWords}`);
  }

  return tagQueue.join("") + wrappers.join("") + tagStack.reverse().join("");
};
